export const defects: TableModule = {
    name: "defects",
    route: "spa.defects",
    defaultOrder: {column: 'created_at', direction: 'asc'},
    useCardView: true,
    useSelection: true,
    withoutPagination: true,
    columns: [
        {
            name: "position",
            sortable: true,
            mobile: true,
        },
        {
            name: "status_id",
            sortable: true,
            mobile: true,
        },
        {
            name: "location",
            sortable: true,
            mobile: true
        },
        {
            name: "created_at",
            sortable: true,
            mobile: true
        },
        {
            name: "latest_activity_created_at",
            sortable: true,
            mobile: true
        }
    ],
    filters: [
        {
            name: "customFilterReadyAt",
            column: "customFilterReadyAt",
            type: "defectReadyAt"
        },
        {
            name: "status_id",
            column: "status_id",
            type: "defectStatus"
        }
    ],
    tools: [
        {
            isCreateAction: true,
            ability: ["create", "reports"]
        },
        {
            isExportAction: true,
        }
    ]
}