import {users} from './tableModules/users'
import {organisations} from "./tableModules/organisations";
import {reports} from "./tableModules/reports";
import {defects} from "./tableModules/defects";

export const tableModules: TableModules = {
    users,
    organisations,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    reports,
    defects
}
