export const reports: TableModule = {
    name: "reports",
    route: "spa.reports",
    pageLinkRoute: "reports-id-reportDefects",
    columns: [
        {
            name: "id",
            pageLink: true,
            sortable: true,
            mobile: true
        },
        {
            name: "object",
            sortable: true,
            sortBy: "object_street|object_city|object_zip",
            mobile: true
        },
        {
            name: "customer",
            sortable: true
        },
        {
            name: "contact_person",
            sortable: true,
            sortBy: "contact_person|contact_email|contact_phone",
            breakpoint: "lg",
        },
        {
            name: "status",
            mobile: true
        }
    ],
    filters: [],
    tools: [
        {
            isCreateAction: true,
            ability: ["create", "reports"]
        }
    ],
    withSearch: true
}